<app-header></app-header>
<app-topheader></app-topheader>

<div class="modalWrap" [style.display]="showModalState && SubdomainsService.subdomain != 'agent' ? 'flex' : 'none'">
    <div class="modalWindow">
        <h3>Change driver</h3>
        <div class="option">
            <div class="value input" style="min-width: 185px;">
                <form class="filtersForm" [formGroup]="formDriver">
                    <mat-select formControlName="driverToChange" placeholder="Driver"
                                (selectionChange)="saveNewDriver()">
                        <mat-option value="0">None</mat-option>
                        <mat-option *ngFor="let driver of drivers"
                                    value="{{driver.id}}">{{driver.full_name}}</mat-option>
                    </mat-select>
                </form>
            </div>
        </div>
        <div class="line">
            <button type="button" class="button red"
                    (click)="this.showModalState = false;">Close
            </button>
        </div>
    </div>
</div>
<button class="generateMultiInvoice" (click)="gen()" *ngIf="this.bookingsForInvoice.length > 0">Gen</button>
<div class="main bookingsComponent">
    <div class="flex subheader" *ngIf="SubdomainsService.subdomain != 'agent'">
        <form class="filtersForm" [formGroup]="form">
            <div class="relative">
                <input class="form-control dateFilter" forma="yyyy-mm-dd" (dateSelect)="dateSelected()"
                       formControlName="startDate" style="width: 150px; opacity: 0;"
                       ngbDatepicker dataformatas="" #d="ngbDatepicker" (focus)="d.toggle()" (blur)="d.toggle()">
                <button type="button" class="dateBtn" (click)="d.toggle()">{{filterDateStart}}</button>
            </div>
            <div class="relative">
                <input class="form-control dateFilter" forma="yyyy-mm-dd" (dateSelect)="dateEndSelected()"
                       formControlName="endDate" style="width: 150px; opacity: 0;"
                       ngbDatepicker dataformatas="" #db="ngbDatepicker" (focus)="db.toggle()" (blur)="db.toggle()">
                <button type="button" class="dateBtn" (click)="db.toggle()">{{filterDateEnd}}</button>
            </div>
            <div class="relative bdgFilterField">
                <mat-form-field class="example-full-width">
                    <input type="text"
                           placeholder="Agent"
                           (change)="submit()"
                           matInput
                           [formControl]="agent"
                           [matAutocomplete]="auto"
                    >
                    <mat-autocomplete (optionSelected)="submit()" #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="relative">
                <mat-select class="carFilter userFilter payment" formControlName="car" (closed)="submit()">
                    <mat-option value="0">Car</mat-option>
                    <mat-option *ngFor="let car of cars; let i = index" value="{{car.id}}">
                        {{car.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="relative">
                <input placeholder="{{ 'filters.Search' | translate }}" formControlName="client"
                       class="filterInput" (change)="submit()">
            </div>
            <div class="relative">
                <mat-select class="userFilter payment" formControlName="partner" (closed)="submit()">
                    <mat-option value="0">Partner</mat-option>
                    <mat-option *ngFor="let partner of partners; let i = index" value="{{partner.id}}">
                        {{partner.full_name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="relative">
                <mat-select class="userFilter payment" formControlName="payment" (selectionChange)="submit()">
                    <mat-option value="0">{{ 'filters.Payment' | translate }}</mat-option>
                    <mat-option value="1">{{ 'filters.Cash' | translate }}</mat-option>
                    <mat-option value="2">{{ 'filters.Bank transfer' | translate }}</mat-option>
                    <mat-option value="3">{{ 'filters.Credit card' | translate }}</mat-option>
                    <mat-option value="4">{{ 'filters.Account' | translate }}</mat-option>
                    <mat-option value="6">{{ 'filters.PayPal' | translate }}</mat-option>
                </mat-select>
            </div>
            <div class="relative">
                <mat-select class="userFilter payment" formControlName="paid" (selectionChange)="submit()">
                    <mat-option value="-1">{{ 'booking.Paid' | translate }}
                        /{{ 'booking.Unpaid' | translate }}</mat-option>
                    <mat-option value="0">{{ 'booking.Unpaid' | translate }}</mat-option>
                    <mat-option value="1">{{ 'booking.Paid' | translate }}</mat-option>
                </mat-select>
            </div>
            <button class="clearFilters" type="button" (click)="clearFilters()">
                <img src="assets/img/update.svg">
            </button>
        </form>
        <a routerLink="new" class="button">{{ 'booking.New booking' | translate }}</a>
    </div>

    <div [class]="SubdomainsService.subdomain == 'agent'?'agentPadding block bookingSection': 'block bookingSection'">
        <div class="bookingsAll">
            <div class="headerBookings" *ngIf="bookingsService.bookings">
                <div class="bookingN">№</div>
                <div class="bookingDate">{{ 'booking.Time' | translate }}</div>
                <div class="bookingPl">{{ 'filters.Pick up' | translate }}</div>
                <div class="bookingPl">{{ 'filters.Drop off' | translate }}</div>
                <div class="bookingPl">{{ 'filters.Client' | translate }}</div>
                <div class="bookingPl"
                     *ngIf="SubdomainsService.subdomain != 'agent'">
                    <button class="sortBtn" type="button"
                            (click)="this.sortBookings('buser_full_name')">{{ 'filters.Agent' | translate }}</button>
                </div>
                <div class="bookingPl hideMob"
                     *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'booking.Type of service' | translate }}</div>
                <div class="bookingPl hideMob"
                     *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'booking.Passengers' | translate }}</div>
                <div class="bookingPr"
                     *ngIf="SubdomainsService.subdomain != 'agent'">
                    <button class="sortBtn" type="button"
                            (click)="this.sortBookings('price_god')">{{ 'bookings.Price' | translate }}</button>
                </div>
                <div class="bookingCl"
                     *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'booking.Payment method' | translate }}</div>
                <div class="bookingPr"
                     *ngIf="SubdomainsService.subdomain != 'agent'">{{ 'bookings.Invoice' | translate }}</div>
            </div>
            <div *ngIf="bookingsService.bookings">
                <div class="bookingsAll noBookings"
                     *ngIf="bookingsService.bookings.length < 1">{{ 'info.There are no bookings' | translate }}
                </div>
            </div>
            <div class="all">
                <div style="position: relative;" *ngFor="let booking of bookingsService.bookings; let i = index">
                    <div *ngIf="checkIfCurrentDate(booking.b_start_date) && this.showDate || i == 0 && this.showDate"
                         class="booking date">{{booking.b_start_date | date: 'MMM dd, EEEE':"+0000"}}</div>

                    <a class="booking {{booking.status == '-1' ? 'red': ''}} {{booking.status == '0' ? 'yellow': ''}}"
                       (click)="goToBooking($event, '/bookings/' + booking.id, booking.bi_bodyguard_id, booking.id)"
                       routerLinkActive="active">
                        <div class="bookingN">{{booking.id}}</div>

                        <div class="bookingDate">
                            <mark [className]="booking.paid == 0 ? 'red' : 'green'">{{booking.b_start_date | date: 'HH:mm':"+0000"}}</mark>
                        </div>

                        <div class="bookingPl {{booking.status == 2 ? 'onWay' : ''}} {{booking.status > 2 ? 'started' : ''}}">
                            <mark>
                                {{booking.pick_up_place?.length > 15 ? booking.pick_up_place?.substring(0, 15) + '...' : booking.pick_up_place}}
                                <!--                                {{booking.pick_up_place}}-->
                            </mark>
                        </div>
                        <!--                        <div class="bookingPl" *ngIf="!booking.pick_up">{{booking.pl_name}}</div>-->

                        <div class="bookingPl {{booking.status == 4 ? 'finished' : ''}}">
                            <mark *ngIf="booking.booking_type != 3">
                                {{booking.drop_off_place?.length > 15 ? booking.drop_off_place?.substring(0, 15) + '...' : booking.drop_off_place}}
                                <!--                                {{booking.drop_off_place}}-->
                            </mark>
                            <mark *ngIf="booking.booking_type == 3">
                                By hours ({{booking.duration}})
                            </mark>
                        </div>

                        <div class="bookingPl">
                            <span *ngIf="booking.user_name?.length > 0 && !booking.client_name?.length">{{booking.user_name?.length > 10 ? booking.user_name?.substring(0, 10) + '...' : booking.user_name}}</span>
                            <span *ngIf="booking.client_name?.length > 0">{{booking.client_name?.length > 10 ? booking.client_name?.substring(0, 10) + '...' : booking.client_name}}</span>
                            <!--                            <span *ngIf="booking.user_name?.length > 0 && !booking.client_name?.length">{{booking.user_name}}</span>-->
                            <!--                            <span *ngIf="booking.client_name?.length > 0">{{booking.client_name}}</span>-->
                        </div>

                        <div class="bookingPl"
                             *ngIf="booking.buser_full_name && SubdomainsService.subdomain != 'agent'">
                            <button type="button">{{booking.buser_full_name}}</button>
                        </div>

                        <div class="bookingPl" *ngIf="!booking.buser_full_name">
                            <button type="button">None</button>
                        </div>

                        <div class="bookingPl hideMob" style="text-align: left;"
                             *ngIf="SubdomainsService.subdomain != 'agent' && booking.bodyguard_type == 4">
                            {{booking.car_name?.length > 0 ? booking.car_name : '-'}}
                        </div>
                        <div class="bookingPl hideMob" style="text-align: left;"
                             *ngIf="SubdomainsService.subdomain != 'agent' && booking.bodyguard_type != 4">
                            {{booking.bt_name?.length > 0 ? booking.bt_name : '-'}}
                        </div>
                        <div class="bookingPl hideMob" style="text-align: center;"
                             *ngIf="SubdomainsService.subdomain != 'agent'">
                            {{booking.passengers?.length > 0 ? booking.passengers : '-'}}
                        </div>

                        <div [className]="booking.paid == 0 ? 'bookingPr red' : 'bookingPr green'"
                             *ngIf="SubdomainsService.subdomain != 'agent'">
                            <mark>
                                €{{booking.real_price > 0 || booking.full_disc == 1 ? booking.real_price : booking.price}}</mark>
                        </div>

                        <div class="bookingCl" *ngIf="SubdomainsService.subdomain != 'agent'">{{booking.pay_name}}</div>

                        <div class="bookingPr" *ngIf="booking.invoice_id && SubdomainsService.subdomain != 'agent'">
                            #{{booking.invoice_id}}</div>
                        <div class="bookingPr invoiceSection"
                             *ngIf="!booking.invoice_id && SubdomainsService.subdomain != 'agent'">
                            <mat-checkbox (change)="markBooking($event, booking)"></mat-checkbox>
                        </div>
                    </a>
                </div>
            </div>
            <div class="total booking" *ngIf="bookingsService.bookings">
                <div class="bookingN"></div>
                <div class="bookingDate">Total</div>
                <div class="bookingPl"></div>
                <div class="bookingPl"></div>
                <div class="bookingPl"></div>
                <div class="bookingPl"></div>
                <div class="bookingPl"></div>
                <div class="bookingPl"
                     *ngIf="SubdomainsService.subdomain != 'agent'"></div>
                <div class="bookingPr"
                     *ngIf="SubdomainsService.subdomain != 'agent'">
                    €{{dollarUSLocale.format(totalRevenue) + '.' + totalRevenueDecimal}}
                </div>
                <div class="bookingCl"
                     *ngIf="SubdomainsService.subdomain != 'agent'"></div>
                <div class="bookingPr"
                     *ngIf="SubdomainsService.subdomain != 'agent'"></div>
            </div>
            <div *ngIf="SubdomainsService.subdomain == 'admin'" class="total booking">
                <div class="option">
                    <div class="key">Show tomorrow planing</div>
                    <div class="value">
                        <mat-slide-toggle [checked]="showTomorrow"
                                          (change)="changeShowTomorrow()"></mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-outlet class="oneBooking"></router-outlet>
</div>
